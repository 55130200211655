@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Sora:wght@300;400;500;600;700&display=swap");

body {
  margin: 0;
  padding: 0;
  font-family: "Inter", sans-serif;
  font-weight: 400;
  background-color: #020208;
  color: #e4e4e7;
  overflow-x: hidden;
}

a {
  color: #00ffcc;
  text-decoration: none;
}

a:hover {
  color: #ff437f;
  text-decoration: underline;
}

/* Custom Scrollbar */
*::-webkit-scrollbar {
  width: 6px;
  height: 5px;
}

*::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(255, 255, 255, 0.3);
}

*::-webkit-scrollbar-thumb {
  background-color: #022432;
}

img.brand_logo {
  width: 162px;
}

.main_navbar {
  z-index: 200;
  border-bottom: 1px solid rgba(68, 68, 79, 0.25);
  background: rgba(52, 52, 64, 0.1);
  backdrop-filter: blur(75px);
}
.main_navbar .menu {
  display: flex;
  align-items: center;
  font-family: Sora;
  gap: 30px;
  color: #9d9e9e;
}
.main_navbar .menu a {
  color: #9d9e9e;
  text-decoration: none !important;
}
.main_navbar .menu a:hover,
.main_navbar .menu a.active {
  color: #e4e4e7;
}
.main_navbar .dropdown-menu {
  border-radius: 10px;
  border: 1px solid rgba(68, 68, 79, 0.25);
  background: rgba(2, 2, 8, 0.5);
  backdrop-filter: blur(25px);
  padding: 15px;
}
.main_navbar .dropdown-menu a {
  padding: 5px;
  display: block;
  width: 100%;
  color: #e4e4e7;

  font-family: Sora;
  font-size: 16px;
}
.main_navbar .dropdown-menu a:hover {
  border-radius: 5px 5px;
  background: linear-gradient(
    90deg,
    rgba(0, 160, 227, 0.25) -0.89%,
    rgba(52, 52, 64, 0) 100%
  );
}
.main_navbar .dropdown-toggle {
  background: transparent !important;
  border: 0 !important;
}
.main_navbar .dropdown-toggle::after {
  background: url("./assets/images/down_arw.png") no-repeat center;
  border: 0;
  width: 10px;
  height: 7px;
  filter: invert(0) brightness(2.5);
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto 0;
  right: -3px;
}
.main_navbar .navbar_right {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.navbar-sticky--moved-up {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  margin-top: -100px;
  -webkit-box-shadow: 0 0.1875rem 0.9375rem 0 rgba(0, 0, 0, 0.15);
  box-shadow: 0 0.1875rem 0.9375rem 0 rgba(0, 0, 0, 0.15);
}

.navbar-sticky--on {
  margin-top: 0;
}

.navbar-sticky--transitioned {
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}
.offcanvas {
  background: #01090d;
}
.btn-close {
  filter: invert(1);
}
.navbar-toggler {
  box-shadow: none !important;
}
/* Connect Wallet */
.primary_modal .modal-content {
  background: linear-gradient(132deg, #022432, #020407);
  border: 0;
}

.primary_modal .modal-header {
  border-bottom: 1px solid #0a415c;
  padding: 20px 24px 15px;
}

.connect_wallet_button {
  border: 2px solid #0a415c;
  background-color: transparent;
  color: #ffffff;
  border-radius: 23px;
  padding: 0 24px;
  outline: 0;
  line-height: 46px;
  height: 52px;
  font-size: 18px;
  width: 100%;
  display: flex;
  align-items: center;
}

.primary_modal .btn-close {
  filter: invert(1);
}

.connect_wallet_button:hover {
  border-color: rgb(19, 87, 121);
}

.primary_modal .modal-header h5 {
  font-size: 24px;
  font-weight: 500;
  margin: 0;
  color: #fff;
}

.connect_wallet_button img {
  margin-right: 15px;
}

.connect_wallet_button span {
  color: #fff;
  font-size: 22px;
  font-weight: 400;
}

.btn-close {
  width: 32px;
  height: 32px;
  color: #fff;
  border: 0;
  /* background: transparent url("../src/assets/images/close_icon.png") no-repeat; */
  opacity: 0.8;
  outline: none;
  box-shadow: none !important;
}

.btn-close:hover {
  opacity: 1;
}

.primary_modal a {
  text-decoration: none;
}

.primary_btn {
  border-radius: 100px;
  background: url("./assets/images/btnbg.png") no-repeat center;
  background-size: cover;
  padding: 10px;
  padding-right: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border: 0;
  color: #e4e4e7;
  font-family: Sora;
  font-size: 18px;
  font-weight: 500;
  position: relative;
  transition: 0.3s ease-out;
}

.primary_btn:hover {
  transform: translateY(-3px);
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 8px 15px rgb(15 30 15);
}

.primary_btn .icon {
  background: rgb(228 228 231 / 25%);
  transition: 0.3s ease-out;
  border-radius: 50%;
}

.primary_btn .icon::before {
  content: "";
  border-radius: 50%;
  background: url("./assets/images/btnicon.svg") no-repeat center;
  width: 30px;
  height: 30px;
  transform: rotate(0deg);
  display: block;
  transition: 0.3s ease-out;
}

.primary_btn:hover .icon {
  background: rgb(228 228 231 / 50%);
}

.primary_btn:hover .icon::before {
  transform: rotate(45deg);
}

.h2tag {
  font-family: Sora;
  font-size: 48px;
  background: linear-gradient(
    283deg,
    #fff 8.22%,
    #9cff45 48.93%,
    rgba(0, 180, 255, 0.78) 101.26%
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  letter-spacing: -2.4px;
  font-weight: 400;
}

.page_header {
  background: url("../src/assets/images/banbg4.png") no-repeat top;
  background-size: 1700px;
  height: 1300px;
  /* background-size:unset ;
  height: 1080px; */
  margin: auto;
  margin-top: -100px;
  display: flex;
  align-items: flex-end;
  justify-content: center;
}
.page_header h1 {
  color: #e4e4e7;
  font-size: 32px;
  font-weight: 400;
  gap: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.page_header h1 span {
  font-family: Sora;
  font-size: 96px;
  font-style: normal;
  font-weight: 700;
  background: linear-gradient(
    283deg,
    #fff 8.22%,
    #9cff45 48.93%,
    rgba(0, 180, 255, 0.78) 101.26%
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.page_header .box {
  margin-bottom: 350px;
}

.scale_box {
  margin-top: -70px;
  padding-bottom: 40px;
}

.scale_box .bgbox {
  border-radius: 50px;
  border: 1px solid rgba(68, 68, 79, 0.25);
  background: rgba(52, 52, 64, 0.1);
  backdrop-filter: blur(75px);
  padding: 48px;
}

.scale_box .bgbox .leftimg {
  border-radius: 25px;
}

.scale_box .box {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  padding: 15px 0;
}

.scale_box .box:last-child {
  border: 0;
}

.scale_box .box h5 {
  font-size: 48px;
  font-weight: 400;
  margin-bottom: 5px;
}

.scale_box .box p {
  margin-bottom: 0;
  font-weight: 400;
  font-size: 18px;
}

.why {
  padding: 40px 0;
  position: relative;
}

.why::before {
  content: "";
  position: absolute;
  background: url("./assets/images/whybg.png") no-repeat center;
  background-position: right center;
  background-position: 90% 50%;
  width: 100%;
  height: 1251px;
  z-index: -1;
}

.why .box {
  border-radius: 20px;
  border: 1px solid transparent;
  background: rgba(52, 52, 64, 0.1);
  padding: 30px;
  display: flex;
  align-items: center;
  gap: 30px;
  color: #9d9e9e;
  margin-bottom: 10px;
  transition: 0.5s ease-out;
}

.why .box:hover {
  color: #e4e4e7;
  border: 1px solid rgba(0, 160, 227, 0.13);
  background: linear-gradient(
    90deg,
    rgba(0, 160, 227, 0.08) -0.89%,
    rgba(52, 52, 64, 0) 100%
  );
}

.why .box .img_box {
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.03);
  min-width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.5s ease-out;
}

.why .box:hover .img_box {
  background: linear-gradient(
    47deg,
    rgba(255, 255, 255, 0.5) 0.13%,
    rgba(0, 160, 227, 0.15) 30.78%,
    rgba(155, 255, 68, 0.35) 96.24%
  );
  box-shadow: 12px 9px 35px 0px rgba(156, 255, 69, 0.2);
}

.why .box .img_box img {
  margin-top: 20px;
  margin-right: 20px;
  position: absolute;
}

.why .box h5 {
  font-size: 20px;
  font-weight: 500;
  line-height: 26px;
  /* 130% */
  letter-spacing: -0.3px;
  margin-bottom: 20px;
}

.why .box p {
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
}

.why .right_img {
  position: relative;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.why .right_img::before {
  content: "";
  position: absolute;
  left: -30px;
  right: 0;
  top: -150px;
  margin: auto;
  width: 90%;
  height: 60%;
  border-radius: 50%;
  background: #00b4ff26;
  filter: blur(71px);
  z-index: -1;
  bottom: 0;
}

.why .right_img::after {
  content: "";
  position: absolute;
  right: -30px;
  bottom: -150px;
  margin: auto;
  width: 80%;
  height: 50%;
  border-radius: 50%;
  background: rgba(155, 255, 68, 0.19);
  filter: blur(91px);
  z-index: -1;
  bottom: 0;
}

.how {
  background: url("./assets/images/howbg.png") no-repeat center;
  background-size: 100% 100%;
  padding: 40px 0;
}

.how .accordion {
  position: relative;
}

.how .accordion::before {
  content: "";
  position: absolute;
  background: rgba(228, 228, 231, 0.5);
  width: 1px;
  left: 0;
  top: -10px;
  bottom: 0;
  height: 74%;
  margin: auto 0;
}

.how .accordion-item {
  box-shadow: none !important;
  border: 0;
  background: transparent;
  border-radius: 0;
  padding: 2px 0;
  padding-left: 30px;
  position: relative;
  padding-bottom: 10px;
}

.how .accordion-item::before {
  content: "";
  position: absolute;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  /* background: linear-gradient(283deg, #FFF 8.22%, #9CFF45 48.93%, rgba(0, 180, 255, 0.78) 101.26%);
  border: 1px solid rgba(228, 228, 231, 0.50); */
  background: url("./assets/images/li_icon.png");
  left: -12px;
  top: 12px;
}

.how .accordion-item button {
  color: #e4e4e7;
  font-weight: 500;
  font-size: 20px;
  background: transparent;
  box-shadow: none !important;
  padding: 10px 0;
}

.how .accordion-body {
  padding: 0;
  font-size: 18px;
  font-weight: 400;
  color: #e4e4e7;
}

.how .accordion-button:not(.collapsed)::after,
.how .accordion-button.collapsed::after {
  display: none;
}

.faq {
  padding: 0px 0 50px;
  padding: 50px 0;
  margin-top: -200px;
  margin-top: 60px !important;
}

.faq .accordion-item {
  box-shadow: none !important;
  border: 0;
  border-bottom: 1px solid #0e0f11;
  background: transparent;
  border-radius: 0;
  padding: 20px 30px;
  position: relative;
}

.faq .accordion-item:nth-last-child(1) {
  border: 0;
}

.faq .accordion-item button {
  color: #fff;
  font-weight: 500;
  font-size: 20px;
  background: transparent;
  box-shadow: none !important;
  padding: 10px 0;
}

.faq .para {
  color: #fff;
  margin-bottom: 0;
}

.faq .accordion-body {
  padding: 15px 0;
}

.faq .accordion-button:not(.collapsed)::after,
.faq .accordion-button.collapsed::after {
  background: url("./assets/images/arw.png") no-repeat center;
  width: 13px;
  height: 8px;
  transition: 0.5s;
}

.join {
  padding: 70px 0 0;
}
.join .bg {
  background: url("./assets/images/joinbg.png") no-repeat center;
  background-size: cover;
  border-radius: 25px;
  padding: 110px 70px;
  border: 1px solid rgba(68, 68, 79, 0.25);
}
.join h3 {
  color: #e4e4e7;
  font-family: Sora;
  font-size: 40px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: -2px;
  text-transform: capitalize;
}
.join h3 span {
  background: linear-gradient(
    283deg,
    #fff 8.22%,
    #9cff45 48.93%,
    rgba(0, 180, 255, 0.78) 101.26%
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-family: Sora;
  font-size: 40px;
  font-style: normal;
  font-weight: 600;
  line-height: 56px;
  letter-spacing: -2px;
  text-transform: capitalize;
}

footer.footer {
  background: rgba(52, 52, 64, 0.1);
  padding-top: 30px;
}

.footer .right_box {
  width: fit-content;
  margin-left: auto;
}
.footer_panel {
  border-bottom: 1px solid rgba(68, 68, 79, 0.75);
  margin-bottom: 10px;
}
.footer_panel a{
  word-break: break-word;
}
.footer_social_links {
  display: flex;
  align-items: center;
  gap: 15px;
  padding: 0;
  list-style-type: none;
}
.footer_social_links a {
  border-radius: 32px;
  background: rgba(52, 52, 64, 0.69);
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff !important;
  font-size: 18px;
  text-decoration: none !important;
  transition: 0.5s ease-out;
}
.footer_social_links a:hover {
  background: linear-gradient(
    47deg,
    rgba(255, 255, 255, 0.5) 0.13%,
    rgba(0, 160, 227, 0.15) 30.78%,
    rgba(155, 255, 68, 0.35) 96.24%
  );
  box-shadow: 6px 1px 10px 0px rgba(156, 255, 69, 0.1);
}
.footer .blw {
  color: #e4e4e7;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  display: flex;
  align-items: center;
  justify-content: space-between;
  line-height: normal;
  padding-bottom: 40px;
}
.footer .blw a {
  color: #e4e4e7;
  text-decoration: none !important;
}
.footer .blw ul {
  list-style-type: none;
  padding: 0;
  display: flex;
  align-items: center;
  gap: 20px;
}
.footer .blw li {
  position: relative;
}
.footer .blw li:not(:last-child):before {
  content: "";
  position: absolute;
  background: #e4e4e7;
  width: 1px;
  height: 90%;
  top: 0;
  bottom: 0;
  margin: auto 0;
  right: -12px;
}

.page_box {
  min-height: 100vh;
  padding-top: 70px;
}
.head_box {
  border-radius: 50px;
  background: rgba(52, 52, 64, 0.1);
  backdrop-filter: blur(25px);
  padding: 20px;
  text-align: center;
}
.head_box h3 {
  color: #e4e4e7;
  text-align: center;
  font-family: Sora;
  font-size: 40px;
  font-weight: 400;
  letter-spacing: -2px;
  margin-bottom: 0;
}
.staking .plan_box {
  border-radius: 30px 30px 0px 0px;
  background: linear-gradient(
    90deg,
    rgba(0, 160, 227, 0.13) 0%,
    rgba(52, 52, 64, 0) 51.56%,
    rgba(156, 255, 69, 0.13) 100%
  );
  backdrop-filter: blur(25px);
  padding: 15px 20px;
  max-width: 80%;
  margin: 0 auto;
  text-align: center;
}
.staking .box {
  border-radius: 30px;
  border: 1px solid rgba(0, 160, 227, 0.13);
  background: linear-gradient(
    139deg,
    rgba(0, 160, 227, 0.08) -4.79%,
    rgba(52, 52, 64, 0) 104.38%
  );
  backdrop-filter: blur(7.5px);
  padding: 40px;
}
.staking .box .inbox {
  border-radius: 15px;
  border: 1px solid rgba(68, 68, 79, 0.25);
  background: rgba(52, 52, 64, 0.1);
  backdrop-filter: blur(75px);
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.staking .box p {
  color: #9d9e9e;
  margin-bottom: 0;
}
.staking .box span {
  color: #e4e4e7;
}
.staking .box .font p,
.staking .box .font span {
  font-size: 18px;
}
.staking .box .accordion-button {
  color: #9d9e9e;
  box-shadow: none !important;
  background: transparent !important;
  border: 0 !important;
  gap: 5px;
  justify-content: center;
}
.staking .box .accordion-button::after {
  margin-left: 0;
  background-image: url("./assets/images/down_arw.png");
  width: 10px;
  height: 7px;
  background-size: contain;
}

.staking .box .accordion-button[aria-expanded="false"] .hide {
  display: none;
}
.staking .box .accordion-button[aria-expanded="true"] .hide {
  display: block;
}

.staking .box .accordion-button[aria-expanded="true"] .detail {
  display: none;
}
.staking .box .accordion-button[aria-expanded="false"] .detail {
  display: block;
}
.staking .box .progress {
  background: #9494a980;
  border-radius: 40px;
  height: 10px;
}
.staking .box .progress-bar {
  border-radius: 40px;
  background-color: #9cff45;
  background-image: linear-gradient(
    135deg,
    rgba(255, 255, 255, 0.15) 25%,
    #72cd22 25%,
    #72cd22 50%,
    rgba(255, 255, 255, 0.15) 50%,
    rgba(255, 255, 255, 0.15) 75%,
    #72cd22 75%,
    #72cd22
  );
}
.staking .box .blue .progress-bar {
  background-color: #00b4ff;
  background-image: linear-gradient(
    135deg,
    rgba(255, 255, 255, 0.15) 25%,
    #0079d0 25%,
    #0079d0 50%,
    rgba(255, 255, 255, 0.15) 50%,
    rgba(255, 255, 255, 0.15) 75%,
    #0079d0 75%,
    #0079d0
  );
}

.page_box {
  position: relative;
  z-index: 1;
}
.page_box::before {
  content: "";
  position: absolute;
  background: url("./assets/images/stakebg.png") no-repeat center;
  width: 100%;
  height: 100%;
  background-position: center top;
  position: absolute;
  top: -100px;
  z-index: -1;
}

.detail_box {
  border-radius: 30px;
  border: 1px solid rgba(68, 68, 79, 0.25);
  background: rgba(52, 52, 64, 0.1);
  backdrop-filter: blur(25px);
  padding: 40px;
  display: flex;
  gap: 24px;
  flex-wrap: wrap;
  align-items: center;
}
.detail_box .box {
  border-radius: 15px;
  border: 1px solid rgba(68, 68, 79, 0.25);
  background: rgba(52, 52, 64, 0.1);
  backdrop-filter: blur(75px);
  padding: 20px;
  display: flex;
  align-items: center;
  gap: 20px;
  width: 49% !important;
}
.detail_box .box:not(:first-child) {
  width: 36%;
}
.detail_box .box p span {
  color: #9d9e9e;
}

.staking .box .claim {
  background: linear-gradient(#02040b, #02040b) padding-box,
    linear-gradient(to right, #00b4ffcc, #9cff45ed) border-box;
}
.page_box .nav {
  background: rgba(52, 52, 64, 0.05);
  backdrop-filter: blur(75px);
  border: 0;
  margin-bottom: 15px;
  border-radius: 10px;
}
.page_box .nav li.nav-item {
  width: 33.33333%;
  text-align: center;
}
.page_box .nav .nav-link {
  width: 100%;
  border-radius: 0;
  color: #414148;
  text-align: center;
  font-family: Sora;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
}
.page_box .nav .nav-link.active,
.page_box .nav .nav-link:hover {
  border: 1px solid rgba(0, 160, 227, 0.15);
  background: linear-gradient(
    165deg,
    rgba(0, 160, 227, 0.1) -6.16%,
    rgba(52, 52, 64, 0) 111.08%
  );
  backdrop-filter: blur(7.5px);
  color: #e4e4e7;
}
.page_box .nav li.nav-item:nth-child(1) .nav-link.active,
.page_box .nav li.nav-item:nth-child(1) .nav-link:hover {
  border-radius: 10px 0px 0px 10px;
}
.page_box .nav li.nav-item:nth-child(3) .nav-link.active,
.page_box .nav li.nav-item:nth-child(3) .nav-link:hover {
  border-radius: 0px 10px 10px 0px;
}

.claim {
  border-radius: 100px;
  border: 1px solid transparent;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  color: #e4e4e7;
  padding: 5px 15px;
  border: 1px solid rgb(0 180 255 / 0%);
  background: linear-gradient(#101924, #101924) padding-box,
    linear-gradient(to right, #00b4ffcc, #9cff45ed) border-box;
  transition: 0.5s ease-out;
}
.claim:hover,
.claim.hover {
  background: url("./assets/images/btnbg.png") no-repeat center !important;
  background-size: 120% 120% !important;
}

.history_table ::-webkit-scrollbar {
  width: 6px;
  height: 8px;
}

.history_table ::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(255, 255, 255, 0.116);
  border-radius: 30px;
}

.history_table ::-webkit-scrollbar-thumb {
  background-color: #022432;
  border-radius: 30px;
}

.history_table .flx {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.history_table .flx h4 {
  color: #e4e4e7;
  margin-bottom: 0;
  font-family: Sora;
  font-size: 28px;
  font-style: normal;
  font-weight: 400;
}
.history_table .flx p {
  color: #9d9e9e;
  margin-bottom: 0;
}

.history_table .rdt_Table {
  border: 1px solid rgba(68, 68, 79, 0.25);
  background: rgba(52, 52, 64, 0.1);
  backdrop-filter: blur(75px);
  border-radius: 15px;
  overflow: hidden;
  margin-bottom: 10px;
}
.history_table .rdt_TableRow,
.history_table .rdt_TableHeadRow {
  background: unset;
  color: #e4e4e7;
  font-size: 16px;
  font-weight: 500;
  border-bottom: 1px solid rgba(68, 68, 79, 0.25) !important ;
  border-right: 1px solid rgba(0, 160, 227, 0);
  border-left: 1px solid rgba(0, 160, 227, 0);
}
.history_table .rdt_TableRow:nth-last-child(1) {
  border: 0 !important;
}
.history_table .rdt_TableRow:hover {
  border-right: 1px solid rgba(0, 160, 227, 0.13);
  border-left: 1px solid rgba(0, 160, 227, 0.13);
  background: linear-gradient(
    93deg,
    rgba(0, 160, 227, 0.08) -3.07%,
    rgba(52, 52, 64, 0) 100.82%
  );
}
.history_table .rdt_TableHeadRow {
  color: #9d9e9e;
  font-weight: 400;
  font-size: 16px;
  border-radius: 15px 15px 0px 0px;
}

.history_table .rdt_Pagination {
  background: transparent;
  color: #e4e4e7;
}
.history_table .rdt_Pagination [aria-disabled="true"] {
  fill: #e4e4e7;
}
.history_table .rdt_Pagination [aria-disabled="false"] {
  fill: #9d9e9e;
}

.buytoken {
  padding-top: 150px;
  padding-bottom: 0px;
}

.buytoken .buytokenbg {
  border-radius: 20px;
  background: linear-gradient(180deg, #000203 14.51%, #00090e 126.76%);
  box-shadow: 0px 10px 10px -5px rgb(255 0 0 / 0%),
    0px 20px 25px -5px rgb(0 0 0 / 0%), 0px 1px 2px 0px #1d566f inset;
  padding: 50px 100px;
  position: relative;
  z-index: 1;
  overflow: hidden;
}

.buytoken .buytokenbg::before {
  content: "";
  position: absolute;
  background: radial-gradient(
    98.75% 34.21% at 50% 50%,
    #fff 0%,
    rgba(0, 180, 255, 0.33) 42.19%,
    rgba(0, 0, 0, 0) 100%
  );
  width: 100%;
  height: 100%;
  left: 0;
  right: 0;
  filter: blur(26px);
  border-radius: 60px;
  top: 50%;
  z-index: -1;
}

.buytoken .form-control,
.buytoken .input-group-text {
  background: transparent;
  box-shadow: none !important;
  padding: 10px;
  border-color: #1d566f !important;
  color: #fff;
}

.buytoken .form-control {
  background: #060608;
  color: #fff;
  font-size: 15px;
  border: 1px solid #1a1d21;
  height: 52px;
  /* border-right: 0; */
}

.buytoken .form-control::placeholder {
  color: #fff;
  font-size: 15px;
}

.buytoken .form-control:focus {
  box-shadow: none;
}

.errortext {
  color: red;
}

/* @keyframes reveal {
  from { opacity: 0; clip-path: inset(0% 60% 0% 50%); }
  to { opacity: 1; clip-path: inset(0% 0% 0% 0%); }
} */
@keyframes reveal {
  from {
    transform: scale(0.6);
  }
  to {
    transform: translateZ(0) scale(1);
  }
}

.revealing-image {
  /* view-timeline-name: --revealing-image; */
  animation: auto linear reveal both;
  animation-timeline: view();
  --moz-anianimation-timeline: view();
  -webkit-anianimation-timeline: view();
  /* animation-timeline: --revealing-image; */
  animation-range: entry 25% cover 50%;
}
.rdt_Table > div {
  background: #07070e00;
  color: white;
}




.table_box{
  border: 1px solid rgba(68, 68, 79, 0.25);
  background: rgba(52, 52, 64, 0.1);
  -webkit-backdrop-filter: blur(75px);
  backdrop-filter: blur(75px);
  border-radius: 15px;
  overflow: hidden;
}
.table_box.scrollcolor .head + div >div:nth-child(3)>div{
  background-color: #022432 !important;
}
.table_box .head div{
  color: #9d9e9e !important;
}
.table_box .flx{
  display: flex;
  align-items: center;
  border-bottom: 1px solid #16171e;
  overflow: hidden;
}
.table_box .body .flx:last-child{
  border: 0;
}
.table_box .flx:hover{
  background: linear-gradient( 93deg, rgba(0, 160, 227, 0.08) -3.07%, rgba(52, 52, 64, 0) 100.82% );
}
.table_box .flx >div{
  width: 50%;
  padding:10px 15px;
    color: #e4e4e7;
    font-weight: 500;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}





@media (max-width: 1799px) {
}
@media (max-width: 1699px) {
  .page_header {
    background-size: unset;
    height: 1083px;
  }
  .page_header .box {
    margin-bottom: 290px;
  }
}

@media (max-width: 1399px) {
  .detail_box .box{
    width: 48% !important;
  }
}
@media (max-width: 1199px) {
  .h2tag {
    font-size: 38px;
  }
  .page_header h1 span {
    font-size: 66px;
  }
  .scale_box .box h5 {
    font-size: 38px;
  }
  .join .bg {
    padding: 40px 70px;
  }
  .staking .box {
    padding: 20px;
    border-radius: 20px;
  }
  .staking .box .inbox {
    padding: 15px;
  }
  .detail_box {
    padding: 20px;
    flex-direction: column;
  }
  .detail_box .box {
    padding: 15px;
    width: 100% !important;
  }
  .page_box .nav .nav-link {
    font-size: 21px;
  }
}
@media (max-width: 991px) {
  .main_navbar {
    backdrop-filter: unset;
    background: #01090d;
  }
  .main_navbar .menu {
    flex-direction: column;
  }
  .main_navbar .navbar_right {
    flex-direction: column;
    align-items: center;
    gap: 30px;
  }
  .why {
    overflow: hidden;
  }
  .why .right_img {
    margin: 30px 0;
  }
  .why::before {
    display: none;
  }
  .join .bg {
    padding: 30px;
    z-index: 1;
    position: relative;
  }
  .join .bg::before {
    content: "";
    position: absolute;
    background: #00000057;
    width: 100%;
    height: 100%;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: -1;
    margin: auto;
  }
  .join h3 {
    font-size: 28px;
  }
  .detail_box .box {
    flex-direction: column;
    align-items: flex-start;
  }
  .staking .box .inbox > p:nth-child(2) {
    margin-top: 10px;
  }
  .footer .right_box {
    margin-left: 0;
  }
  .footer .blw {
    flex-direction: column;
    align-items: flex-start;
  }
}
@media (max-width: 767px) {
  .page_header {
    height: 883px;
  }
  .page_header .box {
    margin-bottom: 130px;
  }
  .page_header h1 {
    flex-wrap: wrap;
    gap: 4px;
  }
  .history_table .flx {
    flex-direction: column;
  }
  .history_table .flx h4 {
    margin-bottom: 10px;
  }
  .scale_box,
  .why,
  .join,
  .footer {
    text-align: center;
  }
  .scale_box .box {
    flex-direction: column-reverse;
    align-items: center;
    gap: 15px;
    text-align: center;
  }
  .scale_box .bgbox {
    padding: 20px;
  }
  .why .box {
    flex-direction: column;
  }
  .how .accordion {
    margin-left: 13px;
  }
  .join .primary_btn {
    margin: 0 auto;
  }
  .head_box h3 {
    font-size: 30px;
  }
  .staking .box .inbox {
    flex-direction: column;
    align-items: flex-start;
  }
  .page_box .nav {
    flex-direction: column;
    padding: 10px;
    gap: 5px;
  }
  .page_box .nav .nav-item {
    width: 100% !important;
  }
  .page_box .nav .nav-link {
    border-radius: 10px !important;
  }
  .footer .right_box {
    text-align: center;
    width: 100%;
  }
  .footer .right_box ul {
    justify-content: center;
  }
  .footer .blw {
    align-items: center;
  }
}
@media (max-width: 575px) {
}

.loadBefore::before {
  content: "";
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  background: rgb(10 10 10 / 75%);
  z-index: 1500;
  overflow: hidden;
}
button:disabled {
  opacity: 0.3;
}
.primary_input {
  width: 100%;
  border: 1px solid #0a415c !important;
  background: none;
  padding: 8px;
  border-radius: 10px;
  box-shadow: none !important;
  outline: 0 !important;
  color: #f9f9f9;
}

select.form-select {
  background: #06060800 !important;
  color: #fff !important;
  border-color: #1d566f !important;
  padding: 13px;
}
select.form-select option {
  background: #060608;
}

.affiliate .link {
  border-radius: 5px;
background: rgba(255, 255, 255, 0.05);
display: flex;
padding: 7px; 
gap: 10px;
align-items: center;
margin: 10px 0;
gap: 5px;
width: 80%;
}
.affiliate .link  p{
  color: #fff !important; 
  overflow: hidden;
  text-overflow: ellipsis;
}
.affiliate div{
  white-space: nowrap;
}
.social{
  list-style-type: none;
  display: flex;
  align-items: center;
  gap: 5px;
  justify-content: flex-start;
  margin: 0;
  padding: 0;
}
.social a{
  width: 28px;
height: 28px;
display: flex; 
justify-content: center;
align-items: center;
border-radius: 50%;
background: #02243D;
color: #0B0F22 !important;
text-decoration: none !important;
}
.social a:hover{
  background: linear-gradient(91deg, #24BCFD -5.76%, #FFF 106.09%);
}
.cursor{
  cursor: pointer;
}
.wid {
  width: fit-content;
  margin: 0 auto;
  border-radius: 50px;
}
